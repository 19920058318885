import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { getLog } from "../../api/apiLog";
import "./ScreenShot.css";
const ScreenShot = () => {
  let { name } = useParams();
  const [log, setLog] = useState({});
  const [status, setStatus] = useState(200);
  const getContentLog = async () => {
    try {
      const res = await axios.get(getLog + "/" + name);
      setLog(res.data);
      setStatus(res.status);
    } catch (error) {
      console.log(error);
    }
  };
  function scrollToBottom() {
    const targetDiv = document.getElementById('bodyScreen');
    targetDiv.scrollTop = targetDiv.scrollHeight;
  }

  // Call the function to scroll to the end of the page

  useEffect(() => {
    getContentLog();
    // setTimeout(() => {
    //   scrollToBottom();
    // }, 2000);
  }, []);
  // console.log(window.location.hash);
  if (status === 200) {
    return (
      <div className="main">
        <div id="bodyScreen">
          <h3
            style={{
              display:
                log?.modelSpecial === "" && log?.issueItem === ""
                  ? "block"
                  : "none",
            }}
          >
            No errors were found in the file
          </h3>
          <h3 style={{ display: log?.modelSpecial !== "" ? "block" : "none" }}>
            Extra items:
          </h3>
          {log?.modelSpecial?.split("\n")?.map((line) => (
            <span>
              <span className="lineNumberLog">{line.split("|-|")[0]}</span>
              <span>{line.split("|-|")[1]}</span>
              <span className="itemDetected">
                <a
                  href={"#" + line.split("|-|")[0]}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {line.split("|-|")[2]}
                </a>
              </span>
              <span>{line.split("|-|")[3]}</span>
              <br></br>
            </span>
          ))}
          <h3 style={{ display: log?.issueItem !== "" ? "block" : "none" }}>
            Issue found:
          </h3>
          {log?.issueItem?.split("\n")?.reverse().map((line) => (
            <span>
              <span className="lineNumberLog">{line.split("|-|")[0]}</span>
              <span>{line.split("|-|")[1]}</span>
              <span className="itemDetected">
                <a
                  href={"#" + line.split("|-|")[0]}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {line.split("|-|")[2]}
                </a>
              </span>
              <span>{line.split("|-|")[3]}</span>
              <br></br>
            </span>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1>
          <i>The file was not found.</i>
        </h1>
      </div>
    );
  }
};

export default ScreenShot;
