import axios from "axios";
import React, { useEffect, useState } from "react";
import { addKeyValue, deleteValue, editValue, getKeyValues } from "../api/apiLog";
import { Link } from "react-router-dom";
const ManageValues = () => {
  const [keyValue, setKeyValue] = useState([]);
  const [key, setKey] = useState([]);
  const [addValue, setAddValue] = useState({ key: "CATCH_FAULTY", value: "" });
  const [inputSelect, setInpuSelect] = useState(0)
  const [inputValue, setInputValue] = useState("")
  const getValues = async () => {
    try {
      const res = await axios.post(getKeyValues);
      setKeyValue(res.data.sort((a, b) => a.key.localeCompare(b.key)));
      setKey(res.data?.map((obj) => obj.key));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getValues();
  }, []);
  console.log(inputValue)
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexFlow: "column",
      }}
    >
      <div style={{ height: "80vh", overflow: "auto" }}>
        <table>
          <thead style={{ width: "100%" }}>
            <tr>
              <th
                style={{ position: "sticky", top: 0, backgroundColor: "white" }}
              >
                Key
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  width: "400px",
                }}
              >
                Value
              </th>
              <th
                style={{ position: "sticky", top: 0, backgroundColor: "white" }}
              >
                Option
              </th>
            </tr>
          </thead>
          <tbody>
            {keyValue?.map((value) => (
              <tr
                style={{
                  backgroundColor:
                    value.key === "MODEL_SPECIAL"
                      ? "rgb(219 219 255 / 47%)"
                      : value.key === "CATCH_FAULTY"
                      ? "rgb(166 255 165 / 43%)"
                      : value.key === "MEMORY_DEFAULT"
                      ? "rgb(253 244 52 / 41%)"
                      : "rgb(253 189 152 / 41%)",
                }}
              >
                <td>{value.key}</td>
                <td>
                  <input
                    value={inputSelect===value.id_key?inputValue:value.value}
                    disabled={inputSelect===value.id_key?false:true}
                    style={{ width: "100%" }}
                    onChange={(e)=>{
                      setInputValue(e.target.value)
                      
                    }}
                  ></input>
                </td>
                
                <td>
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "red",
                      color: "white",
                    }}
                    onClick={async () => {
                      const res = await axios.post(deleteValue, {
                        id: value.id_key,
                      });
                      if (res.status !== 200) {
                        alert(res.data);
                      } else {
                        getValues();
                      }
                    }}
                  >
                    Delete
                  </button>
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "yellow",
                      color: "black",
                      display:inputSelect===value.id_key?"none":"inline"
                    }}
                    onClick={async () => {
                      setInpuSelect(value.id_key)
                      setInputValue(value.value)
                    }}
                  >
                    Edit
                  </button>
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "green",
                      color: "white",
                      display:inputSelect===value.id_key?"inline":"none"
                    }}
                    onClick={async () => {
                      setInpuSelect(0)
                      const res = await axios.post(editValue, {
                        id: value.id_key,
                        value: inputValue
                      });
                      if (res.status !== 200) {
                        alert(res.data);
                      } else {
                        getValues();
                      }
                    }}
                  >
                    Done
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ width: "50%", display: "flex", margin: 10 }}>
        <select
          style={{ width: "35%" }}
          onChange={(e) => {
            setAddValue({ ...addValue, key: e.target.value });
          }}
        >
          {key
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            })
            ?.map((u) => (
              <option value={u}>{u}</option>
            ))}
        </select>
        <input
          placeholder="value"
          style={{ margin: "0 5px", width: "50%" }}
          onChange={(e) => {
            setAddValue({ ...addValue, value: e.target.value });
          }}
        ></input>
        <button
          style={{
            cursor: "pointer",
            backgroundColor: "green",
            color: "white",
          }}
          onClick={async () => {
            if (addValue.key !== "" && addValue.value !== "") {
              const res = await axios.post(addKeyValue, {
                key: addValue.key,
                value: addValue.value,
              });
              if (res.status !== 200) {
                alert(res.data);
              } else {
                getValues();
              }
            } else {
              alert("Value is empty!");
            }
          }}
        >
          Add
        </button>
      </div>
      <div>
        <Link to={"/logs"}>
          {" "}
          <button
            style={{
              color: "white",
              backgroundColor: "blue",
              cursor: "pointer",
            }}
          >
            List of detected files
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ManageValues;
