import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { getListLog } from "../../api/apiLog";
import "./ListLog.css";
const ListLog = () => {
  const [listFile, setListFile] = useState([]);
  const [status, setStatus] = useState(200);
  const [nameSearch, setNameSearch] = useState("");
  const getListFile = async () => {
    try {
      const res = await axios.get(getListLog);
      setListFile(res.data);
      setStatus(res.status);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListFile();
  }, []);

  if (status === 200) {
    return (
      <div className="mainList">
        <div className="inputSearch">
            <Link to={"/"}>
              <button
                style={{
                  color: "white",
                  backgroundColor: "blue",
                  cursor: "pointer",
                  float:"left"
                }}
              >
                Home
              </button></Link>
          <label>Search: </label>
          <input value={nameSearch} placeholder={"Enter a file name"} onChange={(e)=>{
            setNameSearch(e.target.value)
          }}></input>
        </div>
        {listFile?.filter((f)=>f.toLocaleLowerCase().search(nameSearch.toLocaleLowerCase())!==-1).map((file) => (
          <div>
            <Link to={"/logs/" + file}>{file}</Link>
            <br></br>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <h1>
          <i>No files</i>
        </h1>
      </div>
    );
  }
};

export default ListLog;
